import * as React from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { lockScroll } from "../../util/generalUtil"
import CloseIcon from "../../../components/svg/CloseIcon"
import { desktop } from "../../constants/screenSizes"
import { Portal } from "components"

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: ${({ show }) => (show ? "0.8" : "0")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 99;
`

const Content = styled.div`
  position: relative;
  width: 100vw;
  max-width: 1000px;
  height: 100vh;
  max-height: 650px;
  background-color: white;
  opacity: ${({ show }) => (show ? "1" : "0")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: all 0.2s ease-in-out;

  @media (max-width: ${desktop}px) {
    max-height: unset;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  z-index: 50;

  path {
    color: red;
  }
`

interface Props {
  show: boolean
  children: React.ReactNode
  onClose: () => void
  className?: string
}

const Modal = ({ show, children, onClose, className }: Props) => {
  useEffect(() => {
    lockScroll(show)
  }, [show])

  return (
    <Portal>
      <div>
        <Container>
          <Content className={className} show={show}>
            <Close onClick={onClose}>
              <CloseIcon />
            </Close>
            {children}
          </Content>
        </Container>
        <Background show={show} onClick={onClose} />
      </div>
    </Portal>
  )
}

export default Modal
