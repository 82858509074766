import * as React from "react"
import styled from "styled-components"
import { useContext, useState } from "react"
import { BasketContext } from "../../context/BasketProvider"
import { Link } from "gatsby"
import { getVendorFromItem } from "../../util/productUtil"
import { PrimaryButton } from "components"

const Container = styled.div``
const TextareaContainer = styled.div`
  width: 100%;
  text-align: left;
  margin: 10px 0;

  label {
  }

  textarea {
    width: 100%;
    height: 100px;
    font-size: 0.8em;
  }

  div {
    font-size: 0.8em;
    margin-top: -10px;
  }
`
const Checkboxes = styled.div`
  margin-bottom: 20px;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
`
const Checkbox = styled.label`
  font-size: 0.8em;
  margin: 10px 0;
  display: block;
  user-select: none;
  cursor: pointer;
  text-align: right;

  input {
    margin-left: 10px;
  }

  a {
    color: #4183c4;
  }
`

const ProceedToCheckoutForm = ({ onProceedToCheckoutClick }) => {
  const [acceptsTerms, setAcceptsTerms] = useState(false)

  const {
    subscribe,
    toggleSubscribe,
    giftNote,
    updateGiftNote,
    deliveryNote,
    updateDeliveryNote,
    basket: { coupons, items },
  } = useContext(BasketContext)

  const someAcceptGiftNote = items.some(item => getVendorFromItem(item)?.acceptsGiftNotes)
  const allAcceptGiftNote = items.every(item => getVendorFromItem(item)?.acceptsGiftNotes)

  return (
    <Container>
      {someAcceptGiftNote && (
        <TextareaContainer>
          <label>
            Gift Note (optional)
            <textarea
              maxLength={300}
              name="giftNote"
              value={giftNote}
              onChange={event => updateGiftNote(event.target.value)}
            />
          </label>
          {!allAcceptGiftNote && (
            <div>Please note: not all brands selected are able to include a gift note</div>
          )}
        </TextareaContainer>
      )}
      <TextareaContainer>
        <label>
          Delivery Note (optional)
          <textarea
            maxLength={140}
            name="deliveryNote"
            value={deliveryNote}
            onChange={event => updateDeliveryNote(event.target.value)}
          />
        </label>
      </TextareaContainer>
      <Checkboxes>
        <Checkbox>
          I have read and agree to the <Link to="/terms-and-conditions">terms</Link> and{" "}
          <Link to="/privacy-and-cookie-policy">privacy policy</Link>, and confirm I am older than
          18
          <input
            name="acceptsTerms"
            type="checkbox"
            checked={acceptsTerms}
            onChange={() => setAcceptsTerms(!acceptsTerms)}
          />
        </Checkbox>
        <Checkbox>
          I would like to receive email updates from Social Supermarket
          <input name="subscribe" type="checkbox" checked={subscribe} onChange={toggleSubscribe} />
        </Checkbox>
      </Checkboxes>
      <StyledPrimaryButton disabled={!acceptsTerms} onClick={onProceedToCheckoutClick}>
        Proceed to Checkout
      </StyledPrimaryButton>
    </Container>
  )
}

export default ProceedToCheckoutForm
